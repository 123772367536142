// const _cache = {}  // key: {value, isChange}
function _set(key) {
  return value => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

function _get(key) {
  return JSON.parse(window.localStorage.getItem(key) || '""')
}

export const accessToken = {
  get: () => _get('accessToken'),
  set: _set('accessToken')
}

export const jurisdiction = {
  get: () => _get('jurisdiction'),
  set: _set('jurisdiction')
}

export const tabIndex = {
  get: () => _get('tabIndex'),
  set: _set('tabIndex')
}
