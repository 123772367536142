import Vue from "vue";
import VueRouter from "vue-router";
import { accessToken } from "../utils/local-cache"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import ('@/views/login/login.vue')
  },
  {
    path: "/menus",
    name: "Menus",
    redirect: "/menus/security",
    component: () => import('@/views/menus/menus.vue'),
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import ('@/views/menus/home.vue')
      },
      {
        path: "nacelle",
        name: "Nacelle",
        component: () => import ('@/views/menus/nacelle-set/nacelle-set.vue')
      },
      {
        path: "security",
        name: "Security",
        component: () => import ('@/views/menus/security-check/security-check.vue')
      },
      {
        path: "system",
        name: "System",
        component: () => import ('@/views/menus/system-setup/system-setup.vue')
      },
      {
        path: "analysis",
        name: "Analysis",
        component: () => import ('@/views/menus/data-analysis/data-analysis.vue')
      }
    ]
  },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
  const token = accessToken.get()
  if (!token && to.path !== '/') {
    next('/');
  } else {
    next()
  }
})
export default router;
