import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);

import directive from '@/utils/directive'
Vue.use(directive)

import Print from 'vue-print-nb'
Vue.use(Print)

/**
 * 引入全局样式
 */
import "@/assets/stylus/index.styl";
import "@/assets/fonts/stylesheet.css";
/**
 * 引入全局组件
 */
import "@/components";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
